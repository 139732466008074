/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
/* eslint-disable import/no-cycle */
import { batch } from 'react-redux';
import { createActions } from 'redux-actions';
import findIndex from 'ramda/src/findIndex';
import identity from 'ramda/src/identity';
import mergeAll from 'ramda/src/mergeAll';
import prop from 'ramda/src/prop';
import propEq from 'ramda/src/propEq';
import update from 'ramda/src/update';
import set from 'ramda/src/set';
import lensPath from 'ramda/src/lensPath';
import uniq from 'ramda/src/uniq';
import { getLocationParametersSelector, getLocationSelector } from 'redux-core/router/selectors';
import { getTrackParams, getTracksSelector, getSelectedTrackSelector } from 'redux-core/tracks/selectors';
import i18next from 'i18next';
import { showSnackbar } from 'redux-core/global-error/actions';
import {
  refreshCueContainersByTrackProp,
  refetchCueContainersById,
  updateCueContainersByTrackId,
} from 'redux-core/cueContainers/actions';
import { requestWithError } from 'utils/request';
import { fetchBookmarkList } from 'redux-core/header/actions';
import { createBookmarkCall, deleteBookmarkCall } from 'redux-core/header/services';
import ROUTES from 'components/Routes/routes';
import { getProductionUseInfo, fetchFooterInfo, getSmartFlags } from 'redux-core/overview/actions';
import { clearanceApi } from 'services/clear/clearances';
import {
  createTrackCall,
  getTracksCall,
  getTrackByIdCall,
  createGenreCall,
  updateTrackCall,
  updateTrackUsing,
  updateTrackActive,
  getTrackDetailsCall,
  getProductionTagsCall,
  updateNoteAndTags,
} from './services';

export const TRACKS_LIMIT = 10;

const actionsDefinition = {
  CLEAR_TRACKS: identity,
  SET_TRACK_SORT: identity,
  SET_LOADING: identity,
  SET_TRACKS_PAGE_COUNT: identity,
  SET_TRACKS_TOTAL_COUNT: identity,
  SET_SELECTED_TRACK: identity,
  SET_TAGS: identity,
  SET_TRACKS: identity,
  SET_CLEARANCE_FORM_DIRTY: identity,
};

export const {
  clearTracks,
  setTrackSort,
  setLoading,
  setTracksPageCount,
  setTracksTotalCount,
  setSelectedTrack,
  setTags,
  setTracks,
  setClearanceFormDirty,
} = createActions(actionsDefinition);

export const fetchTracks =
  (filters = {}) =>
    async (dispatch, getState) => {
      dispatch(setLoading(true));
      const state = getState();
      const tracks = getTracksSelector(state);

      const { divisionId, id: subprojectId } = getLocationParametersSelector(state);

      try {
        const response = await getTracksCall({
          divisionId,
          subprojectId,
          ...filters,
        });
        const newTracks = !filters.page ? response.data : tracks.concat(response.data);
        batch(() => {
          const totalCount = response.pagination?.totalCount;
          dispatch(setTracksPageCount(Math.ceil(totalCount / TRACKS_LIMIT)));
          dispatch(setTracksTotalCount(totalCount));
          dispatch(setTracks(newTracks));
        });
        dispatch(setProductionTags());
        return response;
      } finally {
        dispatch(setLoading(false));
      }
    };

export const refetchTracks = () => (dispatch, getState) => {
  const state = getState();
  const tracks = getTracksSelector(state);
  const trackParams = getTrackParams(state);
  dispatch(fetchFooterInfo());
  return dispatch(
    fetchTracks({
      ...trackParams,
      limit: Math.ceil(tracks.length / TRACKS_LIMIT) * TRACKS_LIMIT,
    }),
  );
};

/**
 * Create a new Track
 * @param {object} payload
 * @param {string} payload.title
 * @param {number} payload.containerId
 * @param {object} settings
 * @param {boolean} settings.isAdding
 */
export const createTrack =
  (payload = {}, { isAdding, isNewPerformance } = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const { id, divisionId } = getLocationParametersSelector(state);

      const extendedPayload = {
        ...payload,
        divisionId,
        duplicate: isNewPerformance,
        subprojectId: id,
      };

      const newTrack = await requestWithError(createTrackCall, extendedPayload, {
        message: {
          success: i18next.t(`drawers.productionSetup.tracks.notification.${isAdding ? 'addTrack' : 'createTrack'}`, {
            name: extendedPayload.title,
          }),
        },
      });

      dispatch(clearanceApi.util.invalidateTags(['clearancesView']));

      const pathname = getLocationSelector(state);
      await dispatch(getProductionUseInfo());
      if (pathname === ROUTES.PRODUCTION.TRACKS) {
        dispatch(refetchTracks());
      } else if (newTrack.frontendAddedValueIsFromRequotePrompt) {
        /**
         * Refresh all cueContainers that have that track. Then, refetch
         * the cueContainer the track was added to
         */
        await dispatch(refreshCueContainersByTrackProp(newTrack.qwireTracksId, 'qwireTracksId'));
        await dispatch(refetchCueContainersById(payload.cueContainerId));
      } else {
        dispatch(refetchCueContainersById(payload.cueContainerId));
      }

      return newTrack;
    };

export const moveTrack =
  (payload = {}, { isNewPerformance } = {}) =>
    async (dispatch, getState) => {
      const state = getState();
      const { id, divisionId } = getLocationParametersSelector(state);

      const extendedPayload = {
        ...payload,
        divisionId,
        duplicate: isNewPerformance,
        subprojectId: id,
      };

      const newTrack = await requestWithError(createTrackCall, extendedPayload, {
        message: {
          success: i18next.t('drawers.clearanceUsesAndDurations.moveMessage', {
            scene: extendedPayload.toSceneName,
          }),
        },
      });

      dispatch(clearanceApi.util.invalidateTags(['clearancesView']));

      const pathname = getLocationSelector(state);
      await dispatch(getProductionUseInfo());
      if (pathname === ROUTES.PRODUCTION.TRACKS) {
        dispatch(refetchTracks());
      } else if (newTrack.frontendAddedValueIsFromRequotePrompt) {
        /**
         * Refresh all cueContainers that have that track. Then, refetch
         * the cueContainer the track was added to
         */
        await dispatch(refreshCueContainersByTrackProp(newTrack.qwireTracksId, 'qwireTracksId'));
        await dispatch(refetchCueContainersById(payload.cueContainerId));
      } else {
        dispatch(refetchCueContainersById(payload.cueContainerId));
      }

      return newTrack;
    };

export const fetchGetTrack = (payload) => async (dispatch) => {
  const data = await getTrackByIdCall({
    ...payload,
    includeRightsOwners: true,
  });
  dispatch(setSelectedTrack(data));
  return data;
};

const updateTrackInStore = (payload) => async (dispatch, getState) => {
  const state = getState();
  const oldTracks = getTracksSelector(state);
  const selectedTrack = getSelectedTrackSelector(state);
  const index = findIndex(propEq('id', payload.id))(oldTracks);
  if (index >= 0) {
    const track = mergeAll([prop(index)(oldTracks), payload]);
    const tracks = update(index, track)(oldTracks);

    dispatch(setTracks(tracks));
    dispatch(setProductionTags());
  } else if (selectedTrack.id === payload.id) {
    const updateTrack = mergeAll([selectedTrack, payload]);
    dispatch(setSelectedTrack(updateTrack));
  } else {
    return oldTracks;
  }
};

/**
 * Refresh Track
 */
export const refreshTrack = (id) => async (dispatch) => {
  const data = await getTrackDetailsCall({ id });
  dispatch(updateTrackInStore(data));
  dispatch(fetchFooterInfo());
};

/**
 * Update Track
 */
export const updateTrack = (payload) => async (dispatch, getState) => {
  const state = getState();
  const pathname = getLocationSelector(state);
  const { divisionId } = getLocationParametersSelector(state);

  const track = {
    divisionId,
    ...payload,
  };

  await updateTrackCall(track);
  await Promise.all([
    pathname.includes(ROUTES.TRACK.BASE_PATH) && dispatch(fetchGetTrack({ id: payload.id })),
    pathname === ROUTES.PRODUCTION.TRACKS
      ? dispatch(refreshTrack(payload.id))
      : dispatch(refreshCueContainersByTrackProp(payload.id)),
  ]);
  const message = i18next.t('tracks.notifications.update.success');
  dispatch(showSnackbar({ message }));
};

export const createGenre = (payload) => async (dispatch) => {
  await createGenreCall(payload);
  const message = i18next.t('tracks.notifications.genre.create.success');
  await dispatch(showSnackbar({ message }));
};

export const updateUsing = (payload) => async (dispatch) => {
  await updateTrackUsing(payload);
  if (payload.cueContainerId) {
    await dispatch(refetchCueContainersById(payload.cueContainerId));
  } else {
    await dispatch(updateTrackInStore(payload));
    await dispatch(fetchFooterInfo());
  }
  const message = i18next.t('tracks.notifications.update.success');
  dispatch(showSnackbar({ message }));
};

export const updateNoteTags = (payload) => async (dispatch, getState) => {
  await updateNoteAndTags(payload);
  if (payload.id) {
    const state = getState();
    const pathname = getLocationSelector(state);

    await Promise.all([
      pathname.includes(ROUTES.TRACK.BASE_PATH) && dispatch(fetchGetTrack({ id: payload.id })),
      pathname === ROUTES.PRODUCTION.TRACKS
        ? dispatch(refreshTrack(payload.id))
        : dispatch(refreshCueContainersByTrackProp(payload.id)),
    ]);
    const message = i18next.t('tracks.notifications.update.success');
    dispatch(showSnackbar({ message }));
  }
};

export const updateActive = (payload) => async (dispatch) => {
  await updateTrackActive(payload);
await dispatch(getSmartFlags());
  if (payload.cueContainerId) {
    await dispatch(refetchCueContainersById(payload.cueContainerId));
  } else await dispatch(refetchTracks());
};

export const TRACK_NOTIFICATIONS = {
  message: {
    success: i18next.t('tracks.notifications.update.success'),
    failed: i18next.t('tracks.notifications.update.failed'),
  },
};

export const updateTrackBookmark = (bookmarkId, trackId) => async (dispatch) => {
  if (bookmarkId) {
    await requestWithError(
      deleteBookmarkCall,
      { id: bookmarkId },
      set(lensPath(['message', 'success']), i18next.t('tracks.notifications.bookmarks.removed'))(TRACK_NOTIFICATIONS),
    );
    dispatch(
      refreshTrackBookmarks({
        id: trackId,
        bookmarkId: undefined,
        bookmarked: false,
        qclearBookmarks: [],
      }),
    );
  } else {
    const { id: bookmarkId } = await requestWithError(
      createBookmarkCall,
      { qclearTrackId: trackId },
      set(lensPath(['message', 'success']), i18next.t('tracks.notifications.bookmarks.created'))(TRACK_NOTIFICATIONS),
    );
    dispatch(refreshTrackBookmarks({ id: trackId, bookmarkId, bookmarked: true }));
  }
};

const refreshTrackBookmarks = (payload) => async (dispatch, getState) => {
  const state = getState();
  const pathname = getLocationSelector(state);
  dispatch(fetchBookmarkList());
  if (pathname === ROUTES.PRODUCTION.TRACKS || pathname.includes(ROUTES.TRACK.BASE_PATH)) {
    dispatch(updateTrackInStore(payload));
  } else {
    dispatch(
      updateCueContainersByTrackId({
        trackId: payload.id,
        bookmarkId: payload.bookmarkId,
      }),
    );
  }
};

export const setProductionTags =
  (newTags = []) =>
    async (dispatch, getState) => {
      const state = getState();
      const { divisionId, id } = getLocationParametersSelector(state);
      const tags = await requestWithError(getProductionTagsCall, {
        divisionId,
        productionId: id,
      });
      const nextTags = uniq([...tags, ...newTags]);
      dispatch(setTags(nextTags));
    };
